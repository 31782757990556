exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "html,\nbody,\n.index_app_3Qs6X {\n    /* probably unecessary, transitional until layout is refactored */\n    width: 100%; \n    height: 100%;\n    margin: 0;\n\n    /* Setting min height/width makes the UI scroll below those sizes */\n    min-width: 1024px;\n    min-height: 640px; /* Min height to fit sprite/backdrop button */\n    overflow: hidden;\n    font-size: 16px;\n}\n\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n\n* { -webkit-box-sizing: border-box; box-sizing: border-box; }\n\n@media screen and (max-height: 1080) {\n    html, body {\n        font-size: 14px;\n    }\n}\n\n@media screen and (max-height: 900px) {\n    html, body {\n        font-size: 12px;\n    }\n}\n\n@media screen and (max-height: 768px) {\n    html, body {\n        font-size: 10px;\n    }\n}", ""]);

// exports
exports.locals = {
	"app": "index_app_3Qs6X"
};