import React from 'react';
import ReactDOM from 'react-dom';
import {compose} from 'redux';

import AppStateHOC from '../lib/app-state-hoc.jsx';
import GUI from '../containers/gui.jsx';
import HashParserHOC from '../lib/hash-parser-hoc.jsx';
import log from '../lib/log.js';

const onClickLogo = () => {
    window.location = 'https://skai.tanqicode.com/';
};

const handleTelemetryModalCancel = () => {
    log('User canceled telemetry modal');
};

const handleTelemetryModalOptIn = () => {
    log('User opted into telemetry');
};

const handleTelemetryModalOptOut = () => {
    log('User opted out of telemetry');
};

/*
*渲染GUI游乐场。这是一个单独的函数，因为导入任何内容
*实例化VM会导致不受支持的浏览器崩溃
*｛object｝appTarget-要呈现到的DOM元素
*/
export default appTarget => {
    GUI.setAppElement(appTarget);

   //请注意，redux的“compose”函数只是用作一个通用的实用程序
//HOC构造函数的层次结构在此调用更清晰；这与redux无关
//合成减速器的能力。
    const WrappedGui = compose(
        AppStateHOC,
        HashParserHOC
    )(GUI);

    //TODO用于测试背包的黑客，允许通过urlparam设置背包主机
    const backpackHostMatches = window.location.href.match(/[?&]backpack_host=([^&]*)&?/);
    const backpackHost = backpackHostMatches ? backpackHostMatches[1] : null;

    const scratchDesktopMatches = window.location.href.match(/[?&]isScratchDesktop=([^&]+)/);
    let simulateScratchDesktop;
    if (scratchDesktopMatches) {
        try {
            //将“true”解析为“true”，将“false”解析为为“false”等。
            simulateScratchDesktop = JSON.parse(scratchDesktopMatches[1]);
        } catch {
            //它不是JSON，所以只需使用字符串
//注意，像“falsy”这样的拼写错误将被视为真
            simulateScratchDesktop = scratchDesktopMatches[1];
        }
    }

    if (process.env.NODE_ENV === 'production' && typeof window === 'object') {
        //离开前发出警告
        window.onbeforeunload = () => true;
    }

    ReactDOM.render(
       //重要：这是检查“simulateScratchDesktop”是否真实，而不仅仅是定义的！
        simulateScratchDesktop ?
            <WrappedGui
                canEditTitle
                isScratchDesktop
                showTelemetryModal
                canSave={false}
                onTelemetryModalCancel={handleTelemetryModalCancel}
                onTelemetryModalOptIn={handleTelemetryModalOptIn}
                onTelemetryModalOptOut={handleTelemetryModalOptOut}
            /> :
            <WrappedGui
                // canEditTitle
                // backpackVisible
                // showComingSoon
                backpackHost={backpackHost}
                canSave={false}
                onClickLogo={onClickLogo}
            />,
        appTarget);
 };
